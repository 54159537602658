html,
body {
  padding: 0;
  margin: 0;
  background: #ffffff;
  color: #242838;
  text-align: center;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  align-self: center;
  justify-content: center;
}

.visual {
  width: 70px;
  margin: 30px;
  margin-top: 50px;
}

.title {
  color: #DD3C57;
  font-size: 49pt;
}

.fullSlot {
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.result {
  padding: 10px 0 10px 0;
  margin: 10px 0 10px 0;
  text-align: center;
  height: 100px;
  overflow: hidden;
  font-size: 1.2em;
  border-radius: 5px;
}



.resultTitle {
  font-size: 1.8em;
  margin: 10px;
}

.resultText {
  font-size: 1.6em;
  margin: 0;
}

.slot {
  position: absolute;
  top: 200px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  overflow: hidden;
}

.pipe {
  background-color: #ffffff;
  border: 2px solid #242838;
  border-left: 0px;;
  border-right: 0px;
  height: 100px;
  position: absolute;
  top:415px;
  left:5%;
  right: 5%;
}

.footer {
  position: absolute;
  top: 700px;
  width: 100%;
}


.rowTitle {
  max-width: 400px;
  min-width: 400px;
  margin: 40px;
  margin-top: 0px;
  font-size: 1.4em;
  color :#242838;
  font-weight: 300;
}

.row {
  height: 400px;
  max-width: 400px;
  min-width: 400px;
  margin: 40px;
  margin-top: 60px;
  flex: 1 0 auto;
  overflow: hidden;
  border: solid 2px #DD3C57;
  border-radius: 200px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  background: linear-gradient(0deg, rgb(204, 203, 203) 0%, rgb(255, 255, 255)15%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 85%, rgb(204, 203, 203) 100%);
}

.ringMoving {
  height: 200px;
  line-height: 3em;
  text-align: center;
  font-size: 12em;
  animation: goaround 0.2s linear infinite;
}

.ringEnd {
  margin-top: ;
  line-height: 200px;
  text-align: center;
  font-size: 9em;
  animation: stop .5s ease-out forwards;
}

@keyframes goaround {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-1000px);
  }
}

@keyframes stop {
  0% {
    transform: translateY(0)
  }

  50% {
    transform: translateY(-90px)
  }

  75% {
    transform: translateY(-80px)
  }

  100% {
    transform: translateY(-85px)
  }
}


.spinButton {
  display: none;
  z-index: 200;
  cursor: pointer;
  text-align: center;
  font-size: 1.2em;
  height: 100px;
  width: 100px;
  background-color: #DD3C57;
  border-radius: 50px;
  border: none;
  color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.spinButton:disabled {
  background-color: #D2D3D6;
}

.cta {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px;
  line-height: 1.7em;
  background-color: #F6F6F7;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.disclaimer {
  color: #6A6D77;
  padding: 8px;
}